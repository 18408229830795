import React,{ Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

});


class CommunityPage extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    render() {
    return(
        <div>
            <h1>Community Page</h1>
        </div>
    )
    }
}


export default withStyles(styles)(CommunityPage);