const ActionTypes = {
    USER_SIGNIN: "USER_SIGNIN",
    USER_SIGNOUT: "USER_SIGNOUT",
    USER_RECEIVE_METADATA: "USER_RECEIVE_METADATA",
    USER_UPDATE_PROFILE: "USER_UPDATE_PROFILE",
    UPDATE_SELECTED: "UPDATE_SELECTED",
    HIDE_MAIN_MENU: "HIDE_MAIN_MENU",
    SHOW_MAIN_MENU: "SHOW_MAIN_MENU",
    MESSAGE_TYPE: "MESSAGE_TYPE"
}

export default ActionTypes;